<template>
  <div>
    <div class="text-h5 my-5">
      {{ $t('Licenses') }}
    </div>
    <HdToggleField
      v-for="app in ac.items"
      :key="app.appId"
      :model="app"
      @valuechange="onValueChange"
    />
  </div>
</template>
<script>
import HdToggleField from './field/HdToggleField'
import { ApplicationCollection } from '../../model/application/ApplicationCollection'

export default {
  name: 'ApplicationToggleList',
  components: {
    HdToggleField
  },  
  props: {
    selectedApplications: {
      type: Array,
      required: false,
      default(){
        return []
      } 
    }
  },

  data() {
    return {
      ac: new ApplicationCollection([])
    }
  },

  watch: {
    selectedApplications: {
      deep: true,
      handler() {

        if(this.selectedApplications.length) {
          this.ac.setItems(this.$store.state.user.applications.map((a) => {  
              return {
                  ...a, 
                isSelected: this.selectedApplications.some((e) => e.appId == a.appId)
              }
            }))          
        } 
        else {
          this.ac.setItems(this.$store.state.user.applications)
        }
      }
    }
  },

  methods: {
    onValueChange(val) {
      this.ac.handleDependencies(val)
    },
    getValues() {
      return this.ac.getEnabledApplications()
    }
  },
}
</script>
