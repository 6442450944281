import { BaseModel } from '../BaseModel'
import adminApiClient from '../../api/admin-api-client'
import authApiClient from '../../api/auth-api-client'

/**
 * Class User
 *
 * @extends BaseModel
 */
export class User extends BaseModel {
  constructor(params) {
    super(params)
    this.ReadOnly = params.ReadOnly || false
    this.userDbId = params.userDbId || 0
    this.companyId = params.companyId || 0
    this.endpointId = params.endpointId || null
    this.groupId = params.groupId > 0 ? params.groupId : 3 //default to portalUser
    this.userId = params.userId || ''
    this.userName = params.userName || ''
    this.phoneNumber = params.phoneNumber || null
    this.timestampUTC = params.timestampUTC || ''
    this.authHash = params.authHash || ''
    this.hasImage = params.hasImage || false
    this.otpEnabled = params.otpEnabled || false
    /**
     * @property {Company} company
     */
    this.company = params.company || null
    /**
     * @property {Group} group
     */
    this.group = params.group || null
    /**
     * @property {Endpoint} endpoint
     */
    this.endpoint = params.endpoint || null
    this.active = params.active || false
    this.isReadOnly = params.isReadOnly || false
    this.password = params.password || ''
    /**
     * @property {ApplicationCollection} applications
     *
     */
    this.applications = params.applications || [] // array is temporary solution
    /**
     * @property {PropertyCollection} properties
     */
    this.properties = params.properties || [] // array is temporary solution
    this.features = params.features || null // todo: user feature class
    /**
     * @property {SubdivisionCollection} subdivisions
     */
    this.subdivisions = params.subdivisions || [] // array is temporary solution

    /**
     * if user's main company is different than the requested company
     * this is set to true
     */
    this.implicit = params.implicit !== undefined ? params.implicit : false // ??params.implicit

    this.externalCompanyUser = params.externalCompanyUser || ''

    this.has2fa = false // 2fa enabled?

    this.defaultMail = params.defaultMail || 'None' //Which email address is used for email (CompanyMail/UserMail/'')

    /**
     * Save the initial state of the object for comparing changes
     */
    this._initialValues = { ...this, _initialValues: null }
  }

  get licences() {
    return this.applications.map((a) => a.applicationDescription).join(', ')
  }

  /**
   * PUT /api/users
   * @override
   *
   * @returns {Promise}
   */
  update() {
    this.isLoading = true

    const userToSave = { ...this }
    userToSave.company = null
    userToSave.endpoint = null
    userToSave.group = null
    userToSave.authHash = null
    userToSave.timestampUTC = null
    userToSave.hasImage = undefined

    return adminApiClient
      .updateUser(this.userDbId, userToSave)
      .then((response) => {
        if (response.status === 200) {
          this.isLoading = false
          return true
        } else {
          throw new Error('Failed to save user')
        }
      })
      .catch((ex) => {
        this.isLoading = false
        throw ex
      })
  }

  is2FaEnabled() {
    if (this.userDbId === 0) {
      return
    }

    return authApiClient
      .is2FAEnabled(this.userDbId)
      .then((response) => {
        this.has2fa = response.data
      })
      .catch((ex) => {
        console.error(ex)
      })
  }

  remove2fa() {
    if (this.userDbId === 0) {
      return
    }
    return authApiClient
      .remove2FA(this.userDbId)
      .then(() => {
        this.has2fa = false
      })
      .catch((ex) => {
        throw ex
      })
  }

  transformForComparison(model) {
    const onlyValues = (props) => {
      return props
        .map((prop) => {
          return {
            propertyValue: prop.propertyValue ? prop.propertyValue.toString().toLowerCase() : prop.propertyValue,
          }
        })
        .filter((prop) => !!prop.propertyValue)
    }
    const reduceApplications = (apps) => {
      return apps.map((app) => {
        return {
          appId: app.appId,
          applicationDescription: app.applicationDescription,
        }
      })
    }
    model.applications = reduceApplications(model.applications)
    model.properties = onlyValues(model.properties)
    return model
  }
  /**
    {
      "userDbId": 0,
      "toCompanyId": 0,
      "toEndpointId": 0,
      "toSubdivisionId": 0,
      "externalCompanyUser": "string"
    }
  * 
  * @param {*} moveObject 
  */
  changeUserCompany(moveObject) {
    return adminApiClient.changeUserCompany(moveObject)
  }

  /**
   * GET /api/Users/{userid}/exists
   *
   * Checks if the userId(email) exists
   *
   * @param {string} userId email
   * @returns {Promise}
   */
  static isUserIdAvailable(userId) {
    return adminApiClient.userExists(userId)
  }
}
